import { regex, required } from "react-admin";

import { UDIStatus } from "./types";

export const versionStringValidator = [
  required(),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  regex(
    /([0-9]+.[0-9]+.[0-9]+)($|-[a-z]+.[0-9]+$)/,
    "Must be a version format"
  ),
];

export const createChoices: Array<{ id: UDIStatus; name: UDIStatus }> = [
  { id: UDIStatus.PROPOSED, name: UDIStatus.PROPOSED },
  { id: UDIStatus.ACTIVE, name: UDIStatus.ACTIVE },
];

export const editChoices: Array<{ id: UDIStatus; name: UDIStatus }> = [
  { id: UDIStatus.PROPOSED, name: UDIStatus.PROPOSED },
  { id: UDIStatus.ACTIVE, name: UDIStatus.ACTIVE },
  { id: UDIStatus.WITHDRAWN, name: UDIStatus.WITHDRAWN },
];
