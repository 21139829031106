export enum PublicationStatus {
  draft = "DRAFT",
  active = "ACTIVE",
  retired = "RETIRED",
  unknown = "UNKNOWN",
  testing = 'TESTING'
}

enum ActivityType {
  Questionnaire = "QUESTIONNAIRE",
  Exercise = "EXERCISE",
}

export enum Language {
  en = "EN",
  de = "DE",
  fr = "FR"
}

export enum RecommendedFrequency {
  ONE_TIME = "ONE_TIME",
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  BI_WEEKLY = "BI_WEEKLY",
  EVERY_FOUR_WEEKS = "EVERY_FOUR_WEEKS",
}

export enum ActivityItemType {
  SECTION_TITLE = "SECTION_TITLE",
  PAGE_BREAK = "PAGE_BREAK",
  PRACTITIONER_INPUT_TEXT = "PRACTITIONER_INPUT_TEXT",
  PRACTITIONER_INPUT_IMAGE = "PRACTITIONER_INPUT_IMAGE",
  PRACTITIONER_INPUT_AUDIO = "PRACTITIONER_INPUT_AUDIO",
  PATIENT_RESPONSE_SHORT_ANSWER = "PATIENT_RESPONSE_SHORT_ANSWER",
  PATIENT_RESPONSE_LONG_ANSWER = "PATIENT_RESPONSE_LONG_ANSWER",
  PATIENT_RESPONSE_NUMBER = "PATIENT_RESPONSE_NUMBER",
  PATIENT_RESPONSE_SINGLE_CHOICE = "PATIENT_RESPONSE_SINGLE_CHOICE",
  PATIENT_RESPONSE_MULTIPLE_CHOICE = "PATIENT_RESPONSE_MULTIPLE_CHOICE",
  PATIENT_RESPONSE_SORTING = "PATIENT_RESPONSE_SORTING",
  PATIENT_RESPONSE_LIKERT_SCALE = "PATIENT_RESPONSE_LIKERT_SCALE",
  PATIENT_RESPONSE_LINEAR_SCALE = "PATIENT_RESPONSE_LINEAR_SCALE",
  PATIENT_RESPONSE_PATIENT_RECORD = "PATIENT_RESPONSE_PATIENT_RECORD",
  PATIENT_RESPONSE_SMILEY_SCALE = "PATIENT_RESPONSE_SMILEY_SCALE",
  PATIENT_RESPONSE_TIME_INPUT_ANSWER = "PATIENT_RESPONSE_TIME_INPUT_ANSWER",
}

export enum PatientRecordType {
  GOAL = "GOAL",
  RESOURCE = "RESOURCE",
  MENTAL_CHALLENGE = "MENTAL_CHALLENGE",
  PREVENTION = "PREVENTION",
  VULNERABILITY = "VULNERABILITY",
  WARNING_SIGN = "WARNING_SIGN",
  PERCEPTION = "PERCEPTION",
  SKILL = "SKILL",
  EMERGENCY_DETECTION = "EMERGENCY_DETECTION",
}

export interface AnswerOption {
  name: string;
  value?: string;
  order?: number;
}

export interface ActivityItem {
  activityItemType: ActivityItemType;
  displayValue: string;
  initialValue?: string;
  semanticIdentifier: string;
  answerOptions?: Array<AnswerOption>;
  order?: number;
  id?: string;
  picture?: { src: string; rawFile?: File; title?: string };
  imageDescription?: string;
  alt?: string;
  version: number;
  recordType?: PatientRecordType;
}

export interface TextWithLink {
  label: string;
  link?: string;
}

interface ScoreInterpretation {
  min: number;
  max: number;
  label: string;
  description?: string;
}

enum CalculationType {
  SUM = "SUM",
  AVG = "AVG",
  VALUE = "VALUE",
}

export enum ActivityModule {
  PREPARATION = "PREPARATION",
  TREATMENT_PLAN = "TREATMENT_PLAN",
  PSYCHOEDUCATION_DEPRESSION = "PSYCHOEDUCATION_DEPRESSION",
  NEEDS_AND_VALUES = "NEEDS_AND_VALUES",
  BEHAVIOR_ACTIVATION = "BEHAVIOR_ACTIVATION",
  RETHINKING = "RETHINKING",
  COMPLETION = "COMPLETION",
  PSYCHOEDUCATION_ANXIETY = 'PSYCHOEDUCATION_ANXIETY',
  TENSION_AND_RELAXATION = 'TENSION_AND_RELAXATION',
  CONFRONTATION = 'CONFRONTATION',
  PANIC_DISORDER = 'PANIC_DISORDER',
  AGORAPHOBIA = 'AGORAPHOBIA',
  GENERALIZED_ANXIETY_DISORDER = 'GENERALIZED_ANXIETY_DISORDER',
  SOCIAL_ANXIETY_DISORDER = 'SOCIAL_ANXIETY_DISORDER',
}

export interface ActivityScore {
  id?: string;
  semanticIdentifier: string;
  calculationType: CalculationType;
  calculationItems: Array<string>;
  name: string;
  isMainScore: boolean;
  isIntermediate: boolean;
  scoreInterpretations: Array<ScoreInterpretation>;
}

export interface EvaluationConfig {
  scoreEvaluations: Array<{
    name: string;
    max: number;
    min: number;
    color: string;
  }>;
  questionEvaluations: Array<{ name: string; value: string; color: string }>;
  questions: Array<{
    displayValue: string;
    semanticIdentifier: string;
    shortName: string;
  }>;
  title: string;
  description: string;
}

export interface Activity {
  id: string;
  status: PublicationStatus;
  activityType: ActivityType;
  language: Language;
  title: string;
  description: string;
  instruction?: string;
  indication?: Array<string>;
  contraIndication?: string;
  recommendedFrequency?: RecommendedFrequency;
  suggestedDuration?: number;
  semanticIdentifier: string;
  version: number;
  items: Array<ActivityItem>;
  author: string;
  references?: Array<TextWithLink>;
  scores: Array<ActivityScore>;
  evaluation?: EvaluationConfig;
  picture?:
    | { src: string; rawFile?: File; title?: string }
    | { src: string; weight: number; height: number };
  activityGoal: string;
  modules?: Array<ActivityModule>;
  practitionerFeedback?: string;
  isEvaluationEnabled?: boolean; //this value is not a part of the dto, it's a helper used to conditionally print form
}

export interface ActivityForCreate
  extends Omit<Activity, "indication"> {
  indication?: Array<string>;
}
