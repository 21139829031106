import Keycloak, { KeycloakConfig, KeycloakTokenParsed } from "keycloak-js";
import { keycloakAuthProvider } from "ra-keycloak";
// ra-keycloak does not have release for KC 24 yet
import { default as RaKeycloak } from "ra-keycloak/node_modules/keycloak-js";
import { DataProvider, fetchUtils } from "react-admin";

import { HealthCheckResponse } from "./dashboard/subtabs/monitoring";
import { Institution, Team } from "./institutions/types";

export interface CustomDataProvider extends DataProvider {
  enableInstitution: (
    institutionId: string,
    dataForEnable: {
      enabled: boolean;
      reason?: string;
    }
  ) => Promise<Institution>;
  getInstitutionTeams: (institutionId: string) => Promise<Array<Team>>;
  assignPractitionerToTeam: (
    institutionId: string,
    practitionerId: string,
    teamId: string
  ) => Promise<unknown>;
  removePractitionerFromTeam: (
    institutionId: string,
    practitionerId: string,
    teamId: string
  ) => Promise<unknown>;
  assignPractitionerToInstitution: (
    institutionId: string,
    practitionerId: string,
    roles: Array<string>
  ) => Promise<unknown>;
  removePractitionerFromInstitution: (
    institutionId: string,
    practitionerId: string
  ) => Promise<unknown>;
  assignPatientToPractitioner: (
    institutionId: string,
    practitionerId: string,
    patientId: string
  ) => Promise<unknown>;
  removePatientFromPractitioner: (
    institutionId: string,
    practitionerId: string,
    patientId: string
  ) => Promise<unknown>;
  assignPatientToInstitution: (
    institutionId: string,
    patientId: string
  ) => Promise<unknown>;
  removePatientFromInstitution: (
    institutionId: string,
    patientId: string
  ) => Promise<void>;
  removeUser: (userId: string) => Promise<void>;
  removeUserData: (userId: string) => Promise<void>;
  addRemoveRequest: (userId: string, reason: string) => Promise<void>;
  forceRemoveAccount: (
    userId: string,
    reason: string,
    role: "PAT" | "MHP"
  ) => Promise<void>;
  healthCheck: (system?: "app" | "idp") => Promise<HealthCheckResponse>;
}

const config: KeycloakConfig = {
  url: process.env.REACT_APP_IDP_URL,
  realm: process.env.REACT_APP_IDP_REALM,
  clientId: "ylah-admin-panel",
};

export const getHeadersForCustomDataProviderMethod = () => {
  const customHeaders = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
  });
  customHeaders.set("Authorization", `Bearer ${keycloakClient.token}`);

  return customHeaders;
};

export const httpClient = (url: string, options?: fetchUtils.Options) => {
  const customHeaders = (options?.headers ||
    new Headers({
      Accept: "application/json",
    })) as Headers;
  customHeaders.set("Authorization", `Bearer ${keycloakClient.token}`);

  if (options) {
    options.headers = customHeaders;

    return fetchUtils.fetchJson(url, options);
  } else {
    const newOptions: fetchUtils.Options = { headers: customHeaders };

    return fetchUtils.fetchJson(url, newOptions);
  }
};

export const keycloakClient = new Keycloak(config);
keycloakClient.onTokenExpired = () => {
  keycloakClient.updateToken(5).catch((error) => console.error(error));
};

const getPermissions = (decoded: KeycloakTokenParsed) => {
  const roles = decoded?.realm_access?.roles;

  if (roles.includes("SUPERUSER")) {
    return "SUPERUSER";
  }

  return undefined;
};

export const authProvider = keycloakAuthProvider(keycloakClient as RaKeycloak, {
  onPermissions: getPermissions,
});
